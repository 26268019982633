import { createApp }    from 'vue'
import   App            from './App.vue'
import { createVuetify} from 'vuetify';
import * as components  from "vuetify/lib/components";
import * as directives  from "vuetify/lib/directives";
import * as labsComponents from 'vuetify/labs/components'
import {createRouter, createWebHistory} from "vue-router";
import                       "@mdi/font/css/materialdesignicons.css";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            name: "home",
            path: "/",
            alias: '/home',
            component: async () => await import("./pages/home-page.vue")
        },
        {
            name: "about",
            path: "/about",
            component: async () => await import("./pages/about-page.vue")
        },
        {
            name: "contact",
            path: "/contact",
            component: async () => await import("./pages/contact-page.vue")
        },
        {
            name: "admin",
            path: "/admin",
            component: async () => await import("./pages/admin-page.vue")
        }
    ]
});



const vuetify = createVuetify({
    components: {
        ...components,
        ...labsComponents
    },
    directives: {
        ...directives
    },
    // theme: {
    //     themes: {
    //         light: {
    //             dark: false,
    //             colors: {
    //                 primary: "red",
    //                 secondary: "#1e252e",
    //                 accent: "#82B1FF",
    //                 error: "#FF5252",
    //                 info: "#2196F3",
    //                 success: "#4CAF50",
    //                 warning: "#FFC107"
    //             }
    //         },
    //         dark: {
    //             dark: true,
    //             colors: {
    //                 primary: "red",
    //                 secondary: "#1e252e",
    //                 accent: "#82B1FF",
    //                 error: "#FF5252",
    //                 info: "#2196F3",
    //                 success: "#4CAF50",
    //                 warning: "#FFC107"
    //             }
    //         }
    //     }
    // }
});


createApp(App).use(router).use(vuetify).mount("#app");
