<template>
  <div id="side-bar">
    <button @click="toggleNavDrawer" id="menu-icon">
      <!-- <v-icon icon="mdi-menu"></v-icon> -->
      <div id="hamburgur-icon" :class="{open: navDrawerOpen}">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
    <div id="nav-items">
      <div class="  " v-for="item in navItems" :key="item.id">
      <router-link :to="item.path" class="nav-item-link" @click="navigate" v-slot="{ href, route, navigate, isActive, isExactActive }"> 
          <div class="nav-item-icon" :class="[isExactActive && 'active']">
            <v-icon :icon="item.icon"></v-icon>
          </div>
          <li class="nav-item-text">
            {{ item.text }}
          </li>
        </router-link>
      </div>
    </div>
  </div>
  <div id="nav-drawer">

  </div>
</template>

<script>


  export default {
    name: 'side-bar',
    data: () => {
      return {
        navDrawerOpen: false
      }
    },
    methods: {
      toggleNavDrawer() {
        this.navDrawerOpen = !this.navDrawerOpen
        this.$emit('toggle-nav-drawer', this.navDrawerOpen);
      },
    },
    props: {
      navItems: {
        type: Array,
        default: () => []
      }
    }
  }
</script>

<style scoped>
  #side-bar                  {  position: sticky; top: 0; left: 0; display: flex; flex-direction: column; align-items: center; width: var(--width-side-bar); height: 100%; background-color: var(--color-surf); box-shadow: 0 0 6px 0 rgba(0,0,0,0.5); z-index: 10; transition: width 0.3s ease-in-out; }
  #nav-items                 { position: relative; top: 0; left: 0; width: 100%; height: 100%; margin-top: 10px;}

  #nav-drawer                { position: absolute; top: 0; left: var(--width-side-bar); width: var(--width-nav-drawer); height: 100%; background-color: var(--color-surf); border-right: 1px solid var(--color-outline); transition: width 0.3s ease-in-out;}
  #menu-icon                 { position: relative; width: 100%; aspect-ratio: 1; display: flex; align-items: center; justify-content: center; color: var(--color-on-surf); background-color: transparent; border: none; border-radius: 15%; }
  #menu-icon i               { width: 40px; height: 40px; font-size: 40px; transition: width 0.3s ease-in-out, height 0.3s ease-in-out, font-size 0.3s ease-in-out;}

  .nav-item                  { position: relative; width: 100%; aspect-ratio: 1; margin-top: 10px; margin-bottom: 10px; padding-bottom: 4px; border-radius: 15%; background-color: var(--color-surf); transition: margin-top 0.3s ease-in-out, margin-bottom 0.3s ease-in-out;}
  .nav-item:active           { background-color: var(--color-surf-cont-highest); }
  .nav-item-link             { position: relative; width: 100%; height: 100%; margin-bottom: 12px; display: flex; flex-direction: column; align-items: center; justify-content: center; text-decoration: none; color: var(--color-on-surf); }
  .nav-item:hover            { background-color: var(--color-surf-cont-high); }
  .nav-item-icon             { position: relative; width: 80%; height: 100%; display: flex; align-items: center; justify-content: center; color: var(--color-on-surf);}
  .nav-item-icon i           { position: relative; width: 100% !important; height: 50px; font-size: 40px; transition: width 0.3s ease-in-out, height 0.3s ease-in-out, font-size 0.3s ease-in-out;}
  .nav-item-icon.active i    { font-size: 50px !important; transition: font-size 0.3s ease-in-out, color 0.1s ease-in-out; color: var(--color-primary);}
  .nav-item-text             { position: relative; width: 100%; text-align: center; font-size: 1em; font-weight: 500; color: var(--color-on-surf); transition: color 0.2s ease-in-out;}

  #hamburgur-icon                        { position: relative; width: 40px; height: 32px; -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); -webkit-transition: .5s ease-in-out; -moz-transition: .5s ease-in-out; -o-transition: .5s ease-in-out; transition: .5s ease-in-out; cursor: pointer;}
  #hamburgur-icon span                   { position: absolute; left: 0; display: block; height: 6px; width: 100%; background: var(--color-on-surf); border-radius: 6px; opacity: 1; -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); -webkit-transition: .25s ease-in-out; -moz-transition: .25s ease-in-out; -o-transition: .25s ease-in-out; transition: .25s ease-in-out; }
  #hamburgur-icon span:nth-child(1)      { top: 0px; -webkit-transform-origin: left center; -moz-transform-origin: left center; -o-transform-origin: left center; transform-origin: left center;}
  #hamburgur-icon span:nth-child(2)      { top: 12px; -webkit-transform-origin: left center; -moz-transform-origin: left center; -o-transform-origin: left center; transform-origin: left center;}
  #hamburgur-icon span:nth-child(3)      { top: 24px; -webkit-transform-origin: left center; -moz-transform-origin: left center; -o-transform-origin: left center; transform-origin: left center;}#hamburgur-icon.open span:nth-child(1) { -webkit-transform: rotate(45deg); -moz-transform: rotate(45deg); -o-transform: rotate(45deg); transform: rotate(45deg); top: -3px; left: 8px;}
  #hamburgur-icon.open span:nth-child(2) { width: 0%; opacity: 0;}
  #hamburgur-icon.open span:nth-child(3) { -webkit-transform: rotate(-45deg); -moz-transform: rotate(-45deg); -o-transform: rotate(-45deg); transform: rotate(-45deg); top: 25px; left: 8px; }



  @media (max-width:  800px) {  .nav-item-text   { color: transparent; }                         }
  @media (max-height: 600px) {  #nav-items       { margin-top: 2px; }                            }
  @media (max-width:  600px) {  #menu-icon i     { width: 32px; height: 32px; font-size:   32px; } }
  @media (max-height: 600px) {  .nav-item        { margin-top: 2px; margin-bottom: 2px; }        }
  @media (max-width:  600px) {  
    .nav-item-icon i { height: 32px; font-size: 32px; }
    .nav-item-icon.active i { font-size: 40px !important; }
  
  }
  @media (max-width:  600px) {  .nav-item-text   { visibility: hidden; height: 0px !important;}  }

</style>
