<template>
  <side-bar :nav-items="navBarItems" @toggle-nav-drawer="toggleNavDrawer()"></side-bar>
  <div id="page-container">
    <router-view></router-view>
  </div>
</template>

<script>

  import SideBar from '@/components/side-bar.vue'

export default {
  name: 'App',
  data: () => {
    return {
      navBarItems: [
        {
          id: 1,
          path: '/home',
          icon: 'mdi-home-circle-outline',
          text: 'Home'
        },
        {
          id: 2,
          path: '/about',
          icon: 'mdi-information-outline',
          text: 'About'
        },
        {
          id: 3,
          path: '/contact',
          icon: 'mdi-email-outline',
          text: 'Contact'
        }
      ]
    }
  },  
  methods: {
    toggleNavDrawer() {
      const app = document.getElementById('app');
      if (app.getAttribute('data-sidebar') === 'open') {
        app.setAttribute('data-sidebar', 'closed');
      } else {
        app.setAttribute('data-sidebar', 'open');
      }
    }
  },
  components: {
    SideBar
  }
}
</script>

<style>

:root {
  --color-primary:          #00599e; 
  --color-on-primary:       #ffffff;
  --color-primary-cont:     #73adff7f;
  --color-on-primary-cont:  #5E2122;


  --color-surf:             #1e252e;

  --color-surf-cont-lowest: #000000;
  --color-surf-cont-low:    #010409;
  --color-surf-cont:        #0d1117;
  --color-surf-cont-high:   #161b22;
  --color-surf-cont-highest:#30363d;

  --color-on-surf:          #ffffff;
  --color-on-surf-varient:  #43483e;
  --color-outline:          #74796d;


  --width-side-bar: clamp(54px, 10vw, 80px);
  --width-nav-drawer: 0px;
}

[data-theme="light"] {
  --color-primary:         #FF5A5F; 
  --color-on-primary:      #ffffff;
  --color-primary-cont:    #FC8D90;
  --color-on-primary-cont: #5E2122;


  --color-surf:          #D9DCD6;

  --color-surf-cont-lowest: #ffffff;
  --color-surf-cont-low:    #f3f5eb;
  --color-surf-cont:        #edefe5;
  --color-surf-cont-high:   #e7e9df;
  --color-surf-cont-highest:#e1e3da;

  --color-on-surf:          #1a1c18;
  --color-on-surf-varient:  #43483e;
  --color-outline:          #74796d;

}

[data-sidebar="open"] {
  --width-nav-drawer: clamp(200px, 30vw, 300px);
}

#app {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;  

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

#page-container {
  position: absolute;
  top: 0;
  left: calc(var(--width-side-bar) + var(--width-nav-drawer));
  height: 100%;
  width: calc(100% - var(--width-side-bar) - var(--width-nav-drawer));
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
  background-color: var(--color-surf-cont);
}

</style>

<style scoped>
  #app-content-container {
    position: absolute;
    top: 40px;
    left: 0px;
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: row;
  }

</style>